import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import {
  ImageContainer,
  GridSpacer,
  MarkedHeading,
  AspectRatioImageContainer
} from 'components';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const UrbanDev = ({ data: { description, headline, image } }) => {
  return (
    // Markup
    <Section id="inspiration-page__urban-dev">
      <Spacer position={0} />
      <Headline data={headline} />
      <Spacer position={1} />
      <Image data={image} />
      <Description data={description} />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => (
  <MarkedHeading
    as="h2"
    data={convertApiHtmlText(data.html)}
    sx={{
      fontSize: ['3.1rem', '3rem', '4rem', '3.8rem', '4.5rem', '4.8rem'],
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 22',
        '1/25',
        '1/span 22',
        '2/span 18'
      ],
      lineHeight: 1.15,
      textTransform: 'uppercase'
    }}
  />
);

const Image = ({ data: { image, alt } }) => (
  <AspectRatioImageContainer
    aspectRatios={1}
    src={image}
    alt={alt}
    sx={{
      alignSelf: 'start',
      gridColumn: [
        '1/13',
        '2/span 10',
        '3/span 20',
        '1/span 11',
        '1/span 11',
        '2/span 11'
      ]
    }}
  />
);

const Description = ({ data }) => (
  <MarkedHeading
    as="p"
    data={convertApiHtmlText(data.html)}
    sx={{
      alignSelf: ['start', 'start', 'start', 'start', 'end', 'end'],
      fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.5rem', '1.5rem', '1.6rem'],
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 22',
        '13/25',
        '13/span 10',
        '14/span 8'
      ],
      lineHeight: 1.5,
      mt: ['4.7rem', '5.6rem', '6.8rem', 0, 0, 0]
    }}
  />
);

const Spacer = ({ position }) => {
  const heights = [
    ['26.9rem', '35.2rem', '28.5rem', '17.5rem', '19.9rem', '27.8rem'],
    ['4.8rem', '5.6rem', '5.1rem', '7.1rem', '8rem', '6.9rem']
  ];

  return <GridSpacer height={heights[position]} />;
};
