import React from 'react';

// External Components
import { Box, Heading } from '@thepuzzlers/pieces';

// Local Components
import {
  ImageContainer,
  GridSpacer,
  LimeNoiseSection,
  AspectRatioBox
} from 'components';

// Animations
import { scaleImage, revealHeadline } from './animations';

export const Header = ({ staticData: { headline }, data: { image } }) => {
  return (
    // Markup
    <ContentWrapper>
      <HeadlineImage data={image} />
      <Headline data={headline} />
      <BottomSpacer />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <LimeNoiseSection
    id="inspiration__header"
    // Animation values
    initial="initial"
    animate="animate">
    {children}
  </LimeNoiseSection>
);

const HeadlineImage = ({ data: { image, alt } }) => {
  return (
    <AspectRatioBox
      aspectRatio={[1.3, 1.3, 1.3, 1.78, 1.78, 1.77]}
      sx={{
        borderRadius: 'card',
        gridColumn: [
          '1/13',
          '1/13',
          '2 / span 22',
          '2 / span 22',
          '3 / span 20',
          '4 / span 18'
        ],
        mt: ['17rem', '21rem', '21.6rem', '14.1rem', '15.3rem', '15.9rem'],
        overflow: 'hidden'
      }}>
      <Box
        className="__animation-box-container"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}>
        <ImageContainer
          src={image}
          alt={alt}
          // Animation value
          variants={scaleImage}
          sx={{ height: '100%', width: '100%' }}
        />
      </Box>
    </AspectRatioBox>
  );
};

const Headline = ({ data }) => {
  return (
    <Heading
      as="h1"
      // Animation value
      variants={revealHeadline}
      sx={{
        lineHeight: 1.15,
        fontFamily: 'primary.normal',
        fontSize: ['3.9rem', '5.6rem', '6.4rem', '3.8rem', '4.8rem', '5.6rem'],
        gridColumn: [
          '1/13',
          '1/13',
          '2 / span 22',
          '2 / span 22',
          '3 / span 20',
          '4 / span 18'
        ],
        mt: ['4rem', '4.8rem', '7.5rem', '3rem', '4.7rem', '4.7rem'],
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Heading as="span">{data[0]}</Heading>
      <Heading
        as="span"
        sx={{
          fontFamily: 'primary.italic',
          alignSelf: ['start', 'start', 'start', 'end', 'end', 'end']
        }}>
        {data[1]}
      </Heading>
    </Heading>
  );
};

const BottomSpacer = () => {
  return (
    <GridSpacer
      height={['31.3rem', '31.2rem', '25.6rem', '19.6rem', '22rem', '24.4rem']}
    />
  );
};
