import React from 'react';

// Elements Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { TextBlockWrapper, GridSpacer, MarkedHeading } from 'components';

// Helper Function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Project = ({ data: { headline, text_block } }) => {
  const textBlocks = [{ text: text_block }];

  return (
    // Markup
    <Section id="inspiration__project">
      <Spacer />
      <Headline data={headline} />
      <TextBlocks data={textBlocks} />
      <Spacer />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => (
  <MarkedHeading
    as="h2"
    data={convertApiHtmlText(data.html)}
    sx={{
      fontSize: ['2.4rem', '2.6rem', '3rem', '2.4rem', '2.8rem', '3.2rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '2/span 18',
        '2/span 22',
        '3/span 17',
        '4/span 15'
      ],
      lineHeight: 1.25,
      pr: [0, '2rem', 0, 0, 0, 0]
    }}
  />
);

const TextBlocks = ({ data }) => (
  <TextBlockWrapper
    className="inspiration__project__text-blocks"
    data={data}
    sx={{
      gap: ['3.2rem', '4.8rem', '6.4rem', '6.7rem', '5rem', '7rem'],
      gridColumn: [
        '1/13',
        '1/span 10',
        '2/span 17',
        '2/span 22',
        '3/span 17',
        '4/span 15'
      ],
      mt: ['4.8rem', '7.2rem', '7rem', '4rem', '5.8rem', '7.2rem']
    }}
    textSx={{
      fontSize: ['1.6rem', '1.6rem', '1.8rem', '1.5rem', '1.5rem', '1.6rem']
    }}
  />
);

const Spacer = () => (
  <GridSpacer
    height={['26.6rem', '29.2rem', '25.8rem', '19.3rem', '24.5rem', '27.2rem']}
  />
);
