import React from 'react';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
import { GalleryRowWrapper, GalleryRowItem, MarkedHeading } from 'components';

// animation
import { staggerImageGallery } from 'sections/animation';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const ImageGallery = ({
  data: {
    description,
    image_one,
    image_two,
    image_three,
    image_four,
    image_five,
    image_six,
    image_seven
  }
}) => {
  const images = [
    { ...image_one },
    { ...image_two },
    { ...image_three },
    { ...image_four },
    { ...image_five },
    { ...image_six },
    { ...image_seven }
  ];

  React.useEffect(() => {
    return staggerImageGallery([
      '.inspiration_image-gallery-row-one',
      '.inspiration_image-gallery-row-two',
      '.inspiration_image-gallery-row-three'
    ]);
  }, []);

  return (
    <Section id="inspiration__image-gallery">
      <Description data={description} />
      <FirstRow data={images} />
      <SecondRow data={images} />
      <ThirdRow data={images} />
    </Section>
  );
};

const Description = ({ data }) => {
  return (
    <MarkedHeading
      as="p"
      data={convertApiHtmlText(data.html)}
      sx={{
        lineHeight: 1.35,
        fontSize: ['2.4rem', '3.2rem', '3.4rem', '2.4rem', '2.8rem', '3.2rem'],
        gridColumn: [
          '1/13',
          '1/13',
          '2/ span 22',
          '1 / span 20',
          '1/ span 17',
          '1/ span 17'
        ],
        mt: ['17.1rem', '22.9rem', '23rem', '14.1rem', '18.1rem', '21.1rem']
      }}
    />
  );
};

const FirstRow = ({ data }) => {
  const closetImage = data[0];
  const rackImage = data[1];
  return (
    <GalleryRowWrapper
      isSmallGap
      sx={{
        mt: ['19rem', '21.1rem', '23rem', '10rem', '13.5rem', '18.9rem']
      }}>
      <GalleryRowItem
        src={closetImage.image}
        alt={closetImage.alt}
        isLarge
        sx={{
          gridRow: [null, null, null, 1, 1, 1]
        }}
        // Animation value
        className="inspiration_image-gallery-row-one"
      />
      <GalleryRowItem
        src={rackImage.image}
        alt={rackImage.alt}
        sx={{
          ml: [null, null, '6rem', 0, 0, 0],
          gridColumn: [null, null, null, 3, 3, 3],
          gridRow: 1
        }}
        // Animation value
        className="inspiration_image-gallery-row-one"
      />
    </GalleryRowWrapper>
  );
};

const SecondRow = ({ data }) => {
  const livingRoom = data[2];
  const kitchen = data[3];
  return (
    <GalleryRowWrapper
      isSmallGap
      sx={{
        mt: ['1.6rem', '1.8rem', '2.4rem', '0.8rem', '0.8rem', '1.6rem']
      }}>
      <GalleryRowItem
        src={livingRoom.image}
        alt={livingRoom.alt}
        sx={{
          justifySelf: 'end',
          mr: [null, null, '6rem', 0, 0, 0]
        }}
        // Animation value
        className="inspiration_image-gallery-row-two"
      />
      <GalleryRowItem
        src={kitchen.image}
        alt={kitchen.alt}
        isLarge
        // Animation value
        className="inspiration_image-gallery-row-two"
      />
    </GalleryRowWrapper>
  );
};

const ThirdRow = ({ data }) => {
  const rackImageOne = data[4];
  const rackImageTwo = data[5];
  const rackImageThree = data[6];

  return (
    <GalleryRowWrapper
      isSmallGap
      isLastRow
      sx={{
        mt: ['1.6rem', '1.8rem', '2.4rem', '0.8rem', '0.8rem', '1.6rem']
      }}>
      <GalleryRowItem
        isFirstItem
        src={rackImageOne.image}
        alt={rackImageOne.alt}
        sx={{
          ml: [null, null, '6rem', 0, 0, 0]
        }}
        // Animation value
        className="inspiration_image-gallery-row-three"
      />
      <GalleryRowItem
        src={rackImageTwo.image}
        alt={rackImageTwo.alt}
        sx={{
          justifySelf: 'end',
          mr: [null, null, '6rem', 0, 0, 0]
        }}
        // Animation value
        className="inspiration_image-gallery-row-three"
      />
      <GalleryRowItem
        src={rackImageThree.image}
        alt={rackImageThree.alt}
        sx={{
          ml: [null, null, '6rem', 0, 0, 0]
        }}
        // Animation value
        className="inspiration_image-gallery-row-three"
      />
    </GalleryRowWrapper>
  );
};
