import React from 'react';

// External Components
import { Heading } from '@thepuzzlers/pieces';

// Local Components
import { TextBlockWrapper, GridSpacer, LimeNoiseSection } from 'components';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Support = ({
  data: { headline, text_block_one, text_block_two }
}) => {
  const textBlocks = [{ text: text_block_one }, { text: text_block_two }];

  return (
    <LimeNoiseSection id="inspiration__support">
      <Headline data={headline} />
      <DescroptionGroup data={textBlocks} />
      <BottomSpacer />
    </LimeNoiseSection>
  );
};

const Headline = ({ data }) => {
  return (
    <Heading
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(data.html) }}
      as="h2"
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.25,
        fontSize: ['2.4rem', '2.6rem', '3rem', '2.4rem', '2.8rem', '3.2rem'],
        gridColumn: [
          '1/13',
          '1/ span 11',
          '1/ span 19',
          '2 / span 22',
          '5 / span 17',
          '6 / span 15'
        ],
        '& > span': {
          fontFamily: 'primary.italic'
        }
      }}
    />
  );
};

const DescroptionGroup = ({ data }) => {
  return (
    <TextBlockWrapper
      className="inspiration__support__text-blocks"
      data={data}
      sx={{
        gap: ['3.2rem', '4.8rem', '6.4rem', '6.7rem', '5rem', '7rem'],
        gridColumn: [
          '1/13',
          '1/ span 10',
          '1/ span 17',
          '2 / span 22',
          '5 / span 17',
          '6 / span 15'
        ],
        mt: ['3.2rem', '7.2rem', '7rem', '4rem', '5.8rem', '7.2rem']
      }}
      textSx={{
        fontSize: ['1.6rem', '1.6rem', '1.8rem', '1.5rem', '1.5rem', '1.6rem']
      }}
    />
  );
  t;
};

const BottomSpacer = () => {
  return (
    <GridSpacer
      height={[
        '23.5rem',
        '34.1rem',
        '29.1rem',
        '14.5rem',
        '21.1rem',
        '33.7rem'
      ]}
    />
  );
};
