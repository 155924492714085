import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  useInspirationPageData,
  Header,
  Support,
  ImageGallery,
  UrbanDev,
  Project
} from 'sections/inspirationPage';

// External Data
import { useKisInspirationPageData } from 'graphqlHooks';

const Inspiration = () => {
  const { seo, header } = useInspirationPageData();

  const {
    header: cmsHeader,
    support: cmsSupport,
    image_gallery: cmsImageGallery,
    urban_dev: cmsUrbanDev,
    project: cmsProject
  } = useKisInspirationPageData();

  return (
    <>
      <SEO {...seo} />
      <Header staticData={header} data={cmsHeader} />
      <Support data={cmsSupport} />
      <ImageGallery data={cmsImageGallery} />
      <UrbanDev data={cmsUrbanDev} />
      <Project data={cmsProject} />
    </>
  );
};

export default injectIntl(Inspiration);
