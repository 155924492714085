import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const staggerImageGallery = (imageGroupPerRows) => {
  // ===Example===
  // const imageGroupPerRows = [
  //   '.member__row-one', // image group on row one
  //   '.member__row-two', // image group on row two
  //   '.member__row-three' // image group on row three
  // ];

  const createAnimationForLandscapeBreakpoints = () => {
    ScrollTrigger.saveStyles(imageGroupPerRows);

    const animations = [];
    imageGroupPerRows.forEach((members) => {
      const animation = gsap.from(members, {
        yPercent: 50,
        stagger: 0.2,
        duration: 0.8,
        scrollTrigger: {
          // the trigger only take one first element
          trigger: members,
          start: 'top bottom'
        }
      });
      animations.push(animation);
    });

    return () => {
      animations.forEach((animation) => animation.kill());
    };
  };

  const createAnimationForPortraitBreakpoints = () => {
    ScrollTrigger.saveStyles(imageGroupPerRows);
    // heed help of gsap utils to array to create screoll trigger for each element
    let imageElements = gsap.utils.toArray(imageGroupPerRows);

    const createSlideUpAnimation = (element) =>
      gsap.from(element, {
        yPercent: 50,
        duration: 0.8,
        scrollTrigger: {
          // the trigger only take one first element
          trigger: element,
          start: 'top bottom'
        }
      });

    const animations = imageElements.map((element) =>
      createSlideUpAnimation(element)
    );

    return () => {
      animations.forEach((animation) => animation.kill());
    };
  };

  ScrollTrigger.matchMedia({
    '(min-width: 700px) and (orientation: landscape)':
      createAnimationForLandscapeBreakpoints,
    '(max-width: 1000px) and (orientation: portrait)':
      createAnimationForPortraitBreakpoints
  });
};
